import axios from "axios";
import { API } from "../config";

import {
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_FAIL,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  DISCOUNT_TIER_LIST_REQUEST,
  DISCOUNT_TIER_LIST_SUCCESS,
  DISCOUNT_TIER_LIST_FAIL,
  DISCOUNT_TIER_CREATE_REQUEST,
  DISCOUNT_TIER_CREATE_SUCCESS,
  DISCOUNT_TIER_CREATE_FAIL,
} from "../constants/customerConstants";

export const listCustomers =
  (search = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOMER_LIST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      };

      const { data } = await axios.get(
        `${API}/api/users/customer-user-list/?phone_number=${search}`,
        config
      );

      dispatch({
        type: CUSTOMER_LIST_SUCCESS,
        payload: data.results,
      });
    } catch (error) {
      dispatch({
        type: CUSTOMER_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateCustomer = (customers) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access_token}`,
      },
    };

    const { data } = await axios.put(
      `${API}/api/users/customer-user-list/${customers.username}/`,
      customers,
      config
    );
    dispatch({
      type: CUSTOMER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteCustomer = (username) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access_token}`,
      },
    };

    const { data } = await axios.delete(
      `${API}/api/users/customer-user-list/${username}/`,
      config
    );

    dispatch({
      type: CUSTOMER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listDiscountTier = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DISCOUNT_TIER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access_token}`,
      },
    };

    const { data } = await axios.get(
      `${API}/api/customers/customer-tier/?discount_percent_limit=15`,
      config
    );

    dispatch({
      type: DISCOUNT_TIER_LIST_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: DISCOUNT_TIER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const createDiscountTier =
  (discountTierName, discountTierPercent, discountTierCap) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DISCOUNT_TIER_CREATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      };

      const { data } = await axios.post(
        `${API}/api/customers/customer-tier/`,
        {
          name: discountTierName,
          discount_percent: discountTierPercent,
          discount_cap: discountTierCap,
        },
        config
      );
      dispatch({
        type: DISCOUNT_TIER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DISCOUNT_TIER_CREATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
