import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import { userLoginReducer, userRegisterReducer } from "./reducers/userReducers";

import { orderChangeReducer, orderListReducer } from "./reducers/orderReducers";
import {
  categoriesListReducer,
  subCategoriesListReducer,
} from "reducers/productReducers";
import {
  outletDetailReducer,
  storeAnalyticsReducer,
} from "reducers/dashboardReducers";
import { outletListReducer } from "reducers/outletReducers";
import {
  customerListReducer,
  customerUpdateReducer,
  customerDeleteReducer,
  discountTierListReducer,
  discountTierCreateReducer,
} from "./reducers/customerReducers";

import { genderListReducer } from "./reducers/commonReducers";
import { productCreateReducer } from "reducers/productReducers";
import { sppCreateReducer } from "reducers/productReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  outletDetail: outletDetailReducer,
  outletList: outletListReducer,
  storeAnalytics: storeAnalyticsReducer,
  sppCreate: sppCreateReducer,
  userRegister: userRegisterReducer,
  ordersList: orderListReducer,
  categoriesList: categoriesListReducer,
  subCategoriesList: subCategoriesListReducer,
  customerList: customerListReducer,
  productCreate: productCreateReducer,
  customerUpdate: customerUpdateReducer,
  customerDelete: customerDeleteReducer,

  discountTierList: discountTierListReducer,
  discountTierCreate: discountTierCreateReducer,
  orderChange: orderChangeReducer,
  customerGenderList: genderListReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// const middlewares = [logger];
// const store = createStore(reducer, initialState,
//     (applyMiddleware(...middlewares,thunk)))

export default store;
