import axios from "axios";
import {
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_CHANGE_FAILURE,
  ORDER_CHANGE_REQUEST,
  ORDER_CHANGE_SUCCESS,
  ORDER_REMOVE_MESSAGE,
} from "../constants/orderConstants";

import { API } from "../config";
const dateToday = new Date().toISOString().slice(0, 10);

export const listOrders = (playBell) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
    ordersList: { orders },
  } = getState();
  let hasNew = false;
  dispatch({
    type: ORDER_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${API}/api/orders/erp-user-order-list/?start_date=${dateToday}&end_date=${dateToday}&outlet_id=${userInfo.user.erp_user.outlet}`,
      { headers: { Authorization: `Bearer ${userInfo.access_token}` } }
    );
    const previousOrderLength = orders
      ? orders.filter((item) => item.order_status_name === "NEW").length
      : 0;
    hasNew =
      previousOrderLength <
      data.data.filter((item) => item.order_status_name === "NEW").length;
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data.data,
      playBell: hasNew && playBell ? playBell : false,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload: error.response ? error.response : error,
    });
  }
};

export const markPrepare = (activeOrder) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();
  dispatch({
    type: ORDER_CHANGE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${API}/api/orders/admin-order-list/${activeOrder.id}/mark-as-in-progress/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      }
    );
    dispatch({
      type: ORDER_CHANGE_SUCCESS,
      payload: data["message"],
    });
  } catch (error) {
    dispatch({
      type: ORDER_CHANGE_FAILURE,
      payload: error.response.data.detail,
    });
  }
};
export const markReady = (activeOrder) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  try {
    const { data } = await axios.put(
      `${API}/api/orders/admin-order-list/${activeOrder.id}/mark-as-completed/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      }
    );
    dispatch({
      type: ORDER_CHANGE_SUCCESS,
      payload: data["message"],
    });
  } catch (error) {
    dispatch({
      type: ORDER_CHANGE_FAILURE,
      payload: error.response.data.detail,
    });
  }
};

export const cancelOrder =
  (activeOrder, remarks) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
    try {
      const { data } = await axios.put(
        `${API}/api/orders/admin-order-list/${activeOrder.id}/mark-as-cancelled/`,
        { remarks },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      dispatch({
        type: ORDER_CHANGE_SUCCESS,
        payload: data["message"],
      });
    } catch (error) {
      dispatch({
        type: ORDER_CHANGE_FAILURE,
        payload: error.response.data.detail,
      });
    }
  };
export const paidOrder = (activeOrder) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  try {
    const { data } = await axios.put(
      `${API}/api/orders/admin-order-list/${activeOrder.id}/paid/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      }
    );
    dispatch({
      type: ORDER_CHANGE_SUCCESS,
      payload: data["message"],
      isPaid: true,
    });
  } catch (error) {
    dispatch({
      type: ORDER_CHANGE_FAILURE,
      payload: error.response.data.detail,
    });
  }
};
export const pickedUpOrder = (activeOrder) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  try {
    const { data } = await axios.put(
      `${API}/api/orders/admin-order-list/${activeOrder.id}/mark-as-picked-up/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      }
    );
    dispatch({
      type: ORDER_CHANGE_SUCCESS,
      payload: data["message"],
    });
  } catch (error) {
    dispatch({
      type: ORDER_CHANGE_FAILURE,
      payload: error.response.data.detail,
    });
  }
};

export const removeErrors = () => (dispatch) => {
  console.log("remove errors called");
  dispatch({
    type: ORDER_REMOVE_MESSAGE,
  });
};
