import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import paymentFailureImage from "../../assets/img/payment_failure.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

function PaymentFailureView() {
  return (
    <div className="mt-4">
      <div className="container">
        <Row className="align-items-center" style={{ marginTop:100, minHeight: "-10vh" }}>
          <Col className="col-md-12">
            <Card className="card-plain mt-8">
              <img  style={{alignSelf: 'center', width: 100, height: 100 }} alt="..." src={paymentFailureImage} />
            </Card>
          </Col>
        </Row>
        <Row>
         <Col className="col-md-12" style={{marginTop:-30}}>
            <div className="col-8 mx-auto text-center mt-4">
            <h5 style={{fontWeight:'bold'}}>
              Sorry, your payment was not successfull. Please try again or contact the payment service provider.
            </h5>
          </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PaymentFailureView;
