import {
    CUSTOMER_LIST_REQUEST,
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAIL,

    CUSTOMER_UPDATE_REQUEST,
    CUSTOMER_UPDATE_SUCCESS,
    CUSTOMER_UPDATE_FAIL,
    CUSTOMER_UPDATE_RESET,

    CUSTOMER_DELETE_REQUEST,
    CUSTOMER_DELETE_SUCCESS,
    CUSTOMER_DELETE_FAIL,
    CUSTOMER_DELETE_RESET,

    DISCOUNT_TIER_LIST_REQUEST,
    DISCOUNT_TIER_LIST_SUCCESS,
    DISCOUNT_TIER_LIST_FAIL,

    DISCOUNT_TIER_CREATE_REQUEST,
    DISCOUNT_TIER_CREATE_SUCCESS,
    DISCOUNT_TIER_CREATE_FAIL,

} from '../constants/customerConstants'


export const customerListReducer = (state = { customers: [] }, action) => {
    switch (action.type) {
        case CUSTOMER_LIST_REQUEST:
            return { loading: true, customers: [] }

        case CUSTOMER_LIST_SUCCESS:
            return {
                loading: false,
                customers: action.payload,
            }

        case CUSTOMER_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const customerUpdateReducer = (state = { customer: {} }, action) => {
    switch (action.type) {
        case CUSTOMER_UPDATE_REQUEST:
            return { loading: true }

        case CUSTOMER_UPDATE_SUCCESS:
            return { loading: false, success: true, customer: action.payload }

        case CUSTOMER_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CUSTOMER_UPDATE_RESET:
            return { customer: {} }

        default:
            return state
    }
}

export const customerDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CUSTOMER_DELETE_REQUEST:
            return { loading: true }

        case CUSTOMER_DELETE_SUCCESS:
            return { loading: false, success: true }

        case CUSTOMER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const discountTierListReducer = (state = { tier: [] }, action) => {
    switch (action.type) {
        case DISCOUNT_TIER_LIST_REQUEST:
            return { loading: true, tier: [] }

        case DISCOUNT_TIER_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                tier: action.payload,
            }

        case DISCOUNT_TIER_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const discountTierCreateReducer = (state = { }, action) => {
    switch (action.type) {
        case DISCOUNT_TIER_CREATE_REQUEST:
            return { loading: true}

        case DISCOUNT_TIER_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                tier: action.payload,
            }

        case DISCOUNT_TIER_CREATE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
