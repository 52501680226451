import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/styles.css";
import moment from "moment";
import useSound from "use-sound";
import sound from "assets/sound/audio-notification.wav";

import React, { useEffect, useState, useRef } from "react";
import { BiCoffeeTogo } from "react-icons/bi";
import {
  BsTelephone,
  BsCircleFill,
  BsClockHistory,
  BsCash,
} from "react-icons/bs";
import { FaRegCheckCircle } from "react-icons/fa";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { HiOutlineClipboardList } from "react-icons/hi";
import {
  MdOutlineCancel,
  MdOutlineCoffeeMaker,
  MdOutlineDeliveryDining,
  MdOutlineNotificationsActive,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  cancelOrder,
  listOrders,
  markPrepare,
  markReady,
  paidOrder,
  pickedUpOrder,
} from "../actions/orderActions";
import { ORDER_REMOVE_MESSAGE } from "constants/orderConstants";
import { timeAgo, phone, makeTitle } from "./utils/conversion";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Alert from "components/Alert/Alert";
import Tabs from "components/Tabs/Tabs";

const BaristaView = () => {
  const now = moment(new Date());

  //All tabs to be shown

  const tabs = [
    {
      icon: <HiOutlineClipboardList size={20} />,
      data: "All Active",
      value: null,
    },
    {
      icon: <MdOutlineNotificationsActive size={20} />,
      data: "New",
      value: "NEW",
    },
    {
      icon: <MdOutlineCoffeeMaker size={20} />,
      data: "Preparing",
      value: "PREPARING",
    },
    {
      icon: <FaRegCheckCircle size={20} />,
      data: "Ready",
      value: "READY",
    },
    {
      icon: <MdOutlineDeliveryDining size={20} />,
      data: "Picked Up",
      value: "PICKED UP",
    },
    {
      icon: <MdOutlineCancel size={20} />,
      data: "Cancelled",
      value: "CANCELLED",
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [msg, setMsg] = useState(null);
  const [shownOrders, setShownOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState({});
  const [remarks, setRemarks] = useState("");

  const [cancelmodal, cancelsetModal] = React.useState(false);
  const canceltoggle = () => cancelsetModal(!cancelmodal);

  const [paidmodal, paidsetModal] = React.useState(false);
  const paidtoggle = () => paidsetModal(!paidmodal);
  const [play] = useSound(sound);

  //get data from redux state
  const dispatch = useDispatch();
  const ordersList = useSelector((state) => state.ordersList);
  const { orders, loading } = ordersList;
  const { success, isPaid, error } = useSelector((state) => state.orderChange);

  //Set shown orders when order change
  useEffect(() => {
    if (activeTab.value) {
      let filterData = orders?.filter(
        (item) => item.order_status_name === activeTab.value
      );
      setShownOrders(filterData);
    } else {
      let filterData = orders?.filter(
        (item) =>
          item.order_status_name === "NEW" ||
          item.order_status_name === "PREPARING" ||
          item.order_status_name === "READY"
      );
      setShownOrders(filterData);
    }
  }, [orders, activeTab]);

  //fetch orders for first time when loaded
  useEffect(() => {
    dispatch(listOrders());
  }, [dispatch]);

  //filter according to activeTab

  //filter orders by customer's phone number
  const handleChange = (event) => {
    const query = event.target.value;
    const filteredList = orders.filter((order) => {
      return order.customer_phone_number.toString().includes(query.toString());
    });
    setShownOrders(filteredList);
  };

  //Refetch data when active tab is changed and refresh button is clicked
  const filterRefresh = () => {
    dispatch(listOrders());
  };

  //Remarks while cancelling order change handler
  const remarksValueChange = (e) => {
    e.preventDefault();
    setRemarks(e.target.value);
  };

  const capitalizeFirstLetterOfEachWord = (word) => {
    if (!!word) {
      const arr = word?.toLowerCase()?.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      return arr.join(" ");
    } else return word;
  };

  //Notification Message handler
  useEffect(() => {
    setMsg(error ? error : success ? success : null);
    dispatch({
      type: ORDER_REMOVE_MESSAGE,
    });
    if (success) {
      filterRefresh(activeTab);
    }
    if (isPaid) {
      setActiveOrder((order) => {
        return {
          ...order,
          paid: true,
        };
      });
    }
  }, [success, error]);

  return (
    <>
      <div className="content">
        <Alert place="tr" color={error ? "danger" : "success"} msg={msg} />

        <Row>
          {/* --------------------- Middle Pannel --------------------- */}

          <Col lg="8" className="pl-0 mb-4">
            <Card className="p-2">
              <Tabs
                activeTab={activeTab}
                tabs={tabs}
                setActiveTab={setActiveTab}
                onTabClick={(tab) => filterRefresh(tab)}
              />
              <Row className="w-100 d-flex justify-content-between align-items-center px-4">
                <CardHeader>
                  <p className="category">{activeTab.data} Orders</p>
                </CardHeader>

                <Row className="align-items-center">
                  <Col className="ml-2">
                    <Button
                      className="d-flex button-light-gray"
                      onClick={() => {
                        filterRefresh(activeTab);
                      }}
                    >
                      <i className="tim-icons icon-refresh-02" />
                      <span className="d-lg-block d-none ml-2"> Refresh </span>
                    </Button>
                  </Col>

                  <Col className="mt-sm-2 ">
                    {/* <i className="tim-icons icon-zoom-split" /> */}
                    <Input
                      className="form_input"
                      placeholder="Number"
                      type="text"
                      onChange={handleChange}
                    ></Input>
                  </Col>
                </Row>
              </Row>

              <CardBody>
                {loading ? (
                  <Card className="mt-4 d-flex justify-content-center align-items-center">
                    <Spinner animation="grow" variant="secondary" />
                  </Card>
                ) : shownOrders && shownOrders.length !== 0 ? (
                  shownOrders.map((item, i) => {
                    const then = moment.duration(now.diff(item.created_at));

                    return (
                      <div
                        key={i}
                        onClick={() => {
                          console.log("clicked");
                          setActiveOrder(item);
                        }}
                      >
                        <Card
                          color={activeOrder.id === item.id ? "default" : ""}
                          className="p-2"
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th className="text-left col-4 m-2">
                                  <BsCircleFill
                                    style={
                                      item.order_status_name === "PREPARING"
                                        ? { color: "#F4C430" }
                                        : item.order_status_name === "NEW"
                                        ? { color: "#36ABEE" }
                                        : item.order_status_name === "CANCELLED"
                                        ? { color: "#E32636" }
                                        : { color: "#2BCC62" }
                                    }
                                  />
                                  <span className="pl-2">
                                    {item.order_status_name}
                                  </span>
                                </th>

                                <th className="text-left col-5">
                                  <BsTelephone size={15} />
                                  <span className="pl-2">
                                    {phone(item.customer_phone_number)}
                                  </span>
                                </th>

                                <th className="text-left col-4">
                                  <BsClockHistory size={15} />
                                  <span className="pl-2">
                                    {timeAgo(then.asMinutes())}
                                  </span>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td className="text-left col-4">
                                  <p
                                    className="text-white badge font-weight-bold p-2"
                                    style={
                                      item.paid
                                        ? {
                                            opacity: 0.8,
                                            letterSpacing: 0.8,
                                            backgroundImage:
                                              "linear-gradient(#2BCC62, #2BCC62)",
                                          }
                                        : {
                                            opacity: 0.8,
                                            letterSpacing: 0.8,
                                            backgroundImage:
                                              "linear-gradient(#FC6454, #FC6454)",
                                          }
                                    }
                                  >
                                    {item.paid
                                      ? capitalizeFirstLetterOfEachWord(
                                          item.order_payment_type
                                        )
                                      : "Unpaid"}
                                  </p>
                                </td>

                                <td className="text-left col-5">
                                  <span
                                    className="font-weight-light"
                                    style={{ opacity: 0.5 }}
                                  >
                                    Customer :
                                  </span>
                                  {item.customer_first_name +
                                    " " +
                                    item.customer_last_name}
                                </td>

                                <td className="text-left col-4">
                                  <span
                                    className="font-weight-light"
                                    style={{ opacity: 0.5 }}
                                  >
                                    Bill No. :
                                  </span>
                                  {item.bill_number.toUpperCase()}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <Card className="p-1 mt-3 text-center">
                    <div
                      className="font-weight-bold mt-4"
                      style={{ opacity: 0.3 }}
                    >
                      No Orders Available
                    </div>
                  </Card>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* --------------------- Right Side Pannel --------------------- */}

          <Col className="pl-0 ">
            <div className="position-sticky" style={{ top: "2em" }}>
              {/* --------------------- Order Details Pannel --------------------- */}
              <Col>
                <Card className="p-2">
                  <CardHeader>
                    <CardTitle>
                      <h4
                        className="text-center font-weight-bold"
                        style={{ color: "#FC6454" }}
                      >
                        Order Details
                      </h4>
                    </CardTitle>
                  </CardHeader>

                  <hr className="m-0"></hr>

                  {/* check if object empty */}
                  {activeOrder === undefined ||
                  activeOrder == null ||
                  Object.keys(activeOrder).length <= 0 ||
                  Object.keys(activeOrder.order_products).length <= 0 ? (
                    <CardBody className="p-1 mt-3 text-center">
                      <div
                        className="font-weight-bold my-4"
                        style={{ opacity: 0.3 }}
                      >
                        No Products Available
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody className="m-0">
                      <Col>
                        <Table className="tablesorter">
                          <thead className="text-primary">
                            <tr>
                              <th>Name</th>
                              <th className="text-right">Qty</th>
                            </tr>
                          </thead>

                          <tbody>
                            {activeOrder.order_products.map((order, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    {makeTitle(order.product_name)}{" "}
                                    {order.product_variant_name &&
                                      `(${makeTitle(
                                        order.product_variant_name
                                      )})`}
                                    {order.redeem && (
                                      <p
                                        className="text-white badge font-weight-bold text-uppercase p-2"
                                        style={{
                                          opacity: 0.8,
                                          letterSpacing: 0.8,
                                          backgroundImage:
                                            "linear-gradient(#2BCC62, #2BCC62)",
                                        }}
                                      >
                                        Redeem
                                      </p>
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {order.quantity}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>

                      <Col className="p-0 m-0 opacity-5 ">
                        <CardBody className="m-0 ">
                          <Input
                            id="exampleText"
                            name="text"
                            className="form_input"
                            style={{ opacity: 0.8 }}
                            // type="textarea"

                            value={activeOrder.special_requests}
                            disabled
                            placeholder="Special Requests"
                          />
                          <Col className="p-0 pt-2"></Col>
                        </CardBody>
                      </Col>

                      <Col className="p-0">
                        <CardHeader>
                          <CardTitle>
                            <h4
                              className="text-center font-weight-bold"
                              style={{ color: "#FC6454" }}
                            >
                              Order Action
                            </h4>
                          </CardTitle>
                        </CardHeader>

                        <CardBody className=" m-0">
                          <Table className="tablesorter">
                            <thead>
                              <tr>
                                <td>
                                  {makeTitle(activeOrder.order_type_name)}
                                </td>
                                <td className="text-right">
                                  {activeOrder.order_type_name ===
                                  "TAKEAWAY" ? (
                                    <BiCoffeeTogo className="text-lg" />
                                  ) : (
                                    <GiForkKnifeSpoon className="text-lg" />
                                  )}
                                </td>
                              </tr>

                              {activeOrder.confirmation_call === true && (
                                <tr>
                                  <td>Confirmation Call</td>
                                  <td className="text-right">
                                    <BsTelephone />
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td className="order-payment-type">
                                  {activeOrder.order_payment_type}
                                </td>
                                <td className="text-right">
                                  <BsCash />
                                </td>
                              </tr>
                            </thead>
                          </Table>
                        </CardBody>
                      </Col>

                      <Col>
                        <Row className="d-flex justify-content-center ">
                          <Button
                            className="btn button-yellow"
                            onClick={() => {
                              dispatch(markPrepare(activeOrder));
                              // setActiveOrder({});
                            }}
                          >
                            Prepare
                          </Button>

                          <Button
                            className="btn-fill button-green"
                            type="submit"
                            onClick={() => {
                              dispatch(markReady(activeOrder));
                              // setActiveOrder({});
                            }}
                          >
                            Ready
                          </Button>

                          <Button
                            className="btn-fill button-red"
                            onClick={canceltoggle}
                          >
                            Cancel
                            {activeOrder === undefined ||
                            activeOrder == null ||
                            Object.keys(activeOrder).length <= 0 ? (
                              ""
                            ) : (
                              <Modal
                                isOpen={cancelmodal}
                                canceltoggle={canceltoggle}
                              >
                                <ModalHeader
                                  canceltoggle={canceltoggle}
                                ></ModalHeader>
                                <ModalBody>
                                  Are you sure you want to cancel this order?
                                </ModalBody>
                                <ModalFooter>
                                  <Form>
                                    <FormGroup style={{ marginLeft: 5 }}>
                                      <Label for="exampleName">Remarks:</Label>
                                      <Input
                                        className="form_input"
                                        style={{ color: "black" }}
                                        type="text"
                                        required="true"
                                        placeholder="Enter Remarks"
                                        value={remarks}
                                        onChange={(e) => remarksValueChange(e)}
                                      />
                                    </FormGroup>
                                    <Button
                                      style={{ marginLeft: 340 }}
                                      color="primary"
                                      onClick={() => {
                                        dispatch(
                                          cancelOrder(activeOrder, remarks)
                                        );
                                        canceltoggle();
                                        setActiveOrder({});
                                      }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      color="primary"
                                      onClick={canceltoggle}
                                    >
                                      No
                                    </Button>
                                  </Form>
                                </ModalFooter>
                              </Modal>
                            )}
                          </Button>
                        </Row>
                      </Col>
                    </CardBody>
                  )}
                </Card>
              </Col>

              {/* --------------------- Payment Detail Pannel --------------------- */}

              <Col>
                <Card className="p-2">
                  <CardHeader>
                    <CardTitle>
                      {" "}
                      <h4
                        className="text-center font-weight-bold"
                        style={{ color: "#FC6454" }}
                      >
                        Payment Detail
                      </h4>
                    </CardTitle>
                  </CardHeader>

                  {activeOrder === undefined ||
                  activeOrder == null ||
                  Object.keys(activeOrder).length <= 0 ||
                  Object.keys(activeOrder.order_products).length <= 0 ? (
                    <CardBody className="p-1 mt-3 text-center">
                      <div
                        className="font-weight-bold my-4"
                        style={{ opacity: 0.3 }}
                      >
                        No Payment Details Available
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <Col>
                        <Table>
                          <tbody>
                            <tr>
                              <td>Sub Total</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.sub_total),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Gross Amount</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.gross_amount),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Product Discount</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.product_discount),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Net Total</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.net_total),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {activeOrder.order_products
                                  .reduce(
                                    (acc, order) =>
                                      acc + Number(order.tier_discount),
                                    0
                                  )
                                  .toFixed(2) /
                                activeOrder.order_products
                                  .reduce(
                                    (acc, order) =>
                                      acc + Number(order.gross_amount),
                                    0
                                  )
                                  .toFixed(2)
                                  ? (
                                      (activeOrder.order_products
                                        .reduce(
                                          (acc, order) =>
                                            acc + Number(order.tier_discount),
                                          0
                                        )
                                        .toFixed(2) /
                                        activeOrder.order_products
                                          .reduce(
                                            (acc, order) =>
                                              acc + Number(order.gross_amount),
                                            0
                                          )
                                          .toFixed(2)) *
                                      100
                                    ).toFixed(1)
                                  : "0"}
                                % Tier Discount
                              </td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.tier_discount),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>10% Service Charge</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.service_charge),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>Taxable Amount</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.taxable_amount),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>13% Vat</td>
                              <td className="text-right">
                                {activeOrder === undefined ||
                                activeOrder == null ||
                                Object.keys(activeOrder).length <= 0
                                  ? ""
                                  : activeOrder.order_products
                                      .reduce(
                                        (acc, order) =>
                                          acc + Number(order.tax_amount),
                                        0
                                      )
                                      .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Bill Amount</strong>
                              </td>

                              <td className="text-right">
                                <strong>
                                  {activeOrder === undefined ||
                                  activeOrder == null ||
                                  Object.keys(activeOrder).length <= 0
                                    ? ""
                                    : activeOrder.order_products
                                        .reduce(
                                          (acc, order) =>
                                            acc + Number(order.bill_amount),
                                          0
                                        )
                                        .toFixed(2)}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col>
                        <Row className="d-flex justify-content-center">
                          <Button
                            className={
                              activeOrder.paid
                                ? "btn-fill button-green"
                                : "btn-fill button-red"
                            }
                            onClick={paidtoggle}
                          >
                            Paid
                            {activeOrder === undefined ||
                            activeOrder == null ||
                            Object.keys(activeOrder).length <= 0 ? (
                              ""
                            ) : (
                              <Modal isOpen={paidmodal} paidtoggle={paidtoggle}>
                                <ModalHeader
                                  paidtoggle={paidtoggle}
                                ></ModalHeader>
                                <ModalBody>
                                  Are you sure this order is paid?
                                </ModalBody>
                                <ModalFooter>
                                  <Form onSubmit={paidtoggle}>
                                    <Button
                                      style={{ marginLeft: 340 }}
                                      color="primary"
                                      onClick={() => {
                                        dispatch(paidOrder(activeOrder));
                                        paidtoggle();
                                      }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      color="primary"
                                      onClick={paidtoggle}
                                    >
                                      No
                                    </Button>
                                  </Form>
                                </ModalFooter>
                              </Modal>
                            )}
                          </Button>

                          <Button
                            className={
                              activeOrder.order_status_name === "PICKED UP"
                                ? "btn-fill button-green"
                                : "btn-fill button-red"
                            }
                            type="submit"
                            onClick={() => {
                              if (!activeOrder.paid) {
                                setMsg("Order is not paid");
                                return;
                              } else {
                                dispatch(pickedUpOrder(activeOrder));
                                setActiveOrder({});
                              }
                            }}
                          >
                            Picked Up
                          </Button>
                        </Row>
                      </Col>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BaristaView;
