import {
  PRODUCT_SEARCH_FAILURE,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_REQUEST,
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_LIST_FAILURE,
  PRODUCT_DELETE_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  SUB_CATEGORIES_LIST_FAILURE,
  SUB_CATEGORIES_LIST_REQUEST,
  SUB_CATEGORIES_LIST_SUCCESS,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_DETAIL_FAILURE,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  SPP_CREATE_REMOVE_MESSAGE,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_REMOVE_MESSAGE,
  SPP_CREATE_REQUEST,
  SPP_CREATE_SUCCESS,
  SPP_CREATE_FAILURE,
} from "../constants/productConstants";

export const productDetailReducer = (state = { product: null }, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL_REQUEST:
      return { loading: true };

    case PRODUCT_DETAIL_SUCCESS:
      return {
        loading: false,
        product: action.payload,
      };

    case PRODUCT_DETAIL_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true, success: false };

    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case PRODUCT_DELETE_FAILURE:
      return { loading: false, error: action.payload, success: false };

    default:
      return state;
  }
};
export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };

    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };

    case PRODUCT_UPDATE_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_SEARCH_REQUEST:
      return { loading: true, products: [] };

    case PRODUCT_SEARCH_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };

    case PRODUCT_SEARCH_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const categoriesListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORIES_LIST_REQUEST:
      return { loading: true, categories: [] };

    case CATEGORIES_LIST_SUCCESS:
      return {
        loading: false,
        categories: action.payload,
      };

    case CATEGORIES_LIST_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const subCategoriesListReducer = (
  state = { subCategories: [] },
  action
) => {
  switch (action.type) {
    case SUB_CATEGORIES_LIST_REQUEST:
      return { loading: true, subCategories: [] };

    case SUB_CATEGORIES_LIST_SUCCESS:
      return {
        loading: false,
        subCategories: action.payload,
      };

    case SUB_CATEGORIES_LIST_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };

    case PRODUCT_CREATE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
        productId: action.productId,
      };

    case PRODUCT_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REMOVE_MESSAGE:
      return { ...state, error: null, success: null };
    default:
      return state;
  }
};

export const sppCreateReducer = (state = { storeProductPrice: [] }, action) => {
  switch (action.type) {
    case SPP_CREATE_REQUEST:
      return { ...state, loading: true };

    case SPP_CREATE_SUCCESS:
      return {
        loading: false,
        sppSuccess: action.payload,
        storeProductPrice: [
          ...state.storeProductPrice.filter((data) => {
            return data.id !== action.sppData.id;
          }),
          action.sppData,
        ],
      };

    case SPP_CREATE_FAILURE:
      return { ...state, loading: false, sppError: action.payload };
    case SPP_CREATE_REMOVE_MESSAGE:
      return { ...state, sppError: null, sppSuccess: null };
    default:
      return state;
  }
};
