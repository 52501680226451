import {
    GENDER_LIST_REQUEST,
    GENDER_LIST_SUCCESS,
    GENDER_LIST_FAIL,

} from '../constants/commonConstants'

export const genderListReducer = (state = { gender: [] }, action) => {
    switch (action.type) {
        case GENDER_LIST_REQUEST:
            return { loading: true, gender: [] }

        case GENDER_LIST_SUCCESS:
            return {
                loading: false,
                gender: action.payload,
            }

        case GENDER_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
