/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import posthog from "posthog-js";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";
import PaymentSuccess from "components/Payment/PaymentSuccess.js";
import PaymentFailure from "components/Payment/PaymentFailure.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
// if (version !== localStorage.getItem("appVersion")) {
//   localStorage.clear();
//   localStorage.setItem("appVersion", version);
// }

ReactDOM.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
            <Route path="/payment/esewa_payment_success/" render={(props) => <PaymentSuccess {...props} />} />
            <Route path="/payment/esewa_payment_failure/" render={(props) => <PaymentFailure {...props} />} />
            <Redirect from="/" to="/admin/barista-view" />
          </Switch>
        </BrowserRouter>
      </Provider>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>,
  document.getElementById("root")
);
let userId = JSON.parse(localStorage.getItem("userInfo"))?.user?.erp_user?.id;
posthog.init("phc_8gRLHMRdVVm8InqgAMzjgdpsYOkDfSN5S9WbQZONVQG", {
  api_host: "https://app.posthog.com",
  loaded: function (posthog) {
    if (userId) {
      posthog.identify(userId);
    }
  },
});
serviceWorkerRegistration.register();
