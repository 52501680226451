import axios from 'axios'
import { API } from '../config';

import {
    GENDER_LIST_REQUEST,
    GENDER_LIST_SUCCESS,
    GENDER_LIST_FAIL,


} from '../constants/commonConstants'

export const listGender = () => async (dispatch,getState) => {
    try {
        dispatch({
            type: GENDER_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }

        const { data } = await axios.get(
            `${API}/api/common/gender-list/`,
            config
        )

        dispatch({
            type: GENDER_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GENDER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

