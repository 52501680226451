/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import useSound from "use-sound";
import sound from "assets/sound/audio-notification.wav";
import { listOrders } from "actions/orderActions";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import appData from "../../../package.json";

import routes from "routes.js";

import logo from "assets/img/xabit_logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { ORDER_NEW_RESET } from "constants/orderConstants";
var ps;

function Admin(props) {
  const location = useLocation();

  console.log("APP VERSION: ", appData.version);
  const history = useHistory();
  if (appData.version !== localStorage.getItem("appVersion")) {
    localStorage.clear();
    history.push("/admin/login-view");
    localStorage.setItem("appVersion", appData.version);
  }
  const { playBell } = useSelector((state) => state.ordersList);
  const mainPanelRef = React.useRef(null);
  const [play] = useSound(sound);
  const dispatch = useDispatch();
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(listOrders(true));
    }, 60000);
    return () => clearInterval(interval);
  });
  useEffect(() => {
    if (playBell) {
      play();
      dispatch({
        type: ORDER_NEW_RESET,
      });
    }
  }, [playBell]);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const loginGetRoutes = (routes) => {
    return routes.map((prop, key) => {
      console.log("prop.path", prop.path);
      if (prop.path === "/login-view") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getSideBarRoutes = (routes) => {
    const route = [];
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path !== "/login-view") {
        const get_route = {
          path: routes[i].path,
          name: routes[i].name,
          rtlName: routes[i].rtlName,
          icon: routes[i].icon,
          component: routes[i].component,
          layout: routes[i].layout,
        };
        route.push(get_route);
      }
    }
    return route;
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          {userInfo ? (
            <div className="wrapper">
              <Sidebar
                routes={getSideBarRoutes(routes)}
                logo={{
                  outterLink: "https:/www.xabitanalytics.com/",
                  text: "Ajna",
                  imgSrc: logo,
                }}
                toggleSidebar={toggleSidebar}
              />
              <div className="main-panel" ref={mainPanelRef} data={color}>
                <AdminNavbar
                  brandText={getBrandText(location.pathname)}
                  toggleSidebar={toggleSidebar}
                  sidebarOpened={sidebarOpened}
                />
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="/admin/barista-view" />
                </Switch>
                {
                  // we don't want the Footer to be rendered on map page
                  location.pathname === "/admin/maps" ? null : <Footer fluid />
                }
              </div>
            </div>
          ) : (
            <div className="wrapper">
              <div className="main-panel" ref={mainPanelRef} data={color}>
                <Switch>
                  {loginGetRoutes(routes)}
                  <Redirect from="*" to="/admin/login-view" />
                </Switch>
              </div>
            </div>
          )}

          {/* <FixedPlugin bgColor={color} handleBgClick={changeColor} /> */}
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
