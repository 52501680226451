import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message/Message";
import FormContainer from "../components/FormContainer/FormContainer";
import { login } from "../actions/userActions";
import loginImage from "../assets/img/login.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  // CardFooter,
  // CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";

function LoginView({ location, history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      console.log("info-if", userInfo.first_name);
      history.push("/admin/dashboard");
    } else {
      console.log("info-else", userInfo);
      history.push("/admin/login-view");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    console.log("this", e);
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <div className="mt-4">
      <div className="container">
        <Row className="align-items-center" style={{ minHeight: "80vh" }}>
          <Col className="col-md-6">
            <Card className="card-plain mt-8">
              <img alt="..." src={loginImage} />
            </Card>
          </Col>

          <Col className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
            <Card className="card-plain mt-10">
              <CardHeader>
                <h2
                  className="font-weight-bolder mb-2"
                  style={{ color: "#ff784d" }}
                >
                  Welcome back
                </h2>
                <p className="mb-0">Enter your email and password to sign in</p>
              </CardHeader>

              <CardBody className="mt-4">
                <div>
                  {error && (
                    <div className="text-center alert_error mb-3 opacity-5 font-weight-bolder">
                      {error}
                    </div>
                  )}
                </div>

                <Form onSubmit={submitHandler}>
                  <FormGroup>
                    <Label
                      for="exampleEmail"
                      className="h6 text-dark"
                      style={{ opacity: 0.8 }}
                    >
                      Email
                    </Label>

                    <Input
                      className="form-control form_input"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label
                      for="examplePassword"
                      className="h6 text-dark"
                      style={{ opacity: 0.8 }}
                    >
                      Password
                    </Label>

                    <Input
                      className="form-control form_input"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup check>
                    <Label
                      className="form-check-label text-dark mb-2"
                      style={{ opacity: 0.8 }}
                    >
                      <Input type="checkbox" /> Remember me
                      <span className="form-check-sign bg-dark">
                        <span className="check"></span>
                      </span>
                    </Label>
                  </FormGroup>

                  <Button className="w-100 mt-4 mb-0 text-white button-dark-gray">
                    Sign in
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-8 mx-auto text-center mt-4">
            <p className="mb-0 copyright_mid" style={{ opacity: 0.5 }}>
              Copyright © {new Date().getFullYear()} Xabit Analytics.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginView;
