import axios from "axios";
import {
  OUTLET_DETAILS_FAILURE,
  OUTLET_DETAILS_REQUEST,
  OUTLET_DETAILS_SUCCESS,
  STORE_ANALYTICS_REQUEST,
  STORE_ANALYTICS_SUCCESS,
  STORE_ANALYTICS_FAILURE,
} from "../constants/dashboardConstants";

import { API } from "../config";
const dateToday = new Date().toISOString().slice(0, 10);

export const getOutletDetails = () => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();
  dispatch({
    type: OUTLET_DETAILS_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${API}/api/companies/outlets/${userInfo.user.erp_user.outlet}/`
    );
    console.log(data);
    dispatch({
      type: OUTLET_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OUTLET_DETAILS_FAILURE,
      payload: error.response ? error.response : error,
    });
  }
};
export const getStoreAnalytics =
  (start_date, end_date) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();
    const outlet_id = userInfo.user.erp_user.outlet;
    dispatch({
      type: STORE_ANALYTICS_REQUEST,
    });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access_token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const response = await axios.post(
        `${API}/api/analytics/dashboard-store/`,
        {
          start_date: start_date,
          end_date: end_date,
          outlet_id: outlet_id,
        },
        config
      );
      console.log(response.data.data);
      dispatch({
        type: STORE_ANALYTICS_SUCCESS,
        payload: response.data.data[0],
      });
    } catch (error) {
      console.log(error.message);
      dispatch({
        type: STORE_ANALYTICS_FAILURE,
        payload: error.response.data?.detail
          ? error.response?.data?.detail
          : error,
      });
    }
  };
