export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_NEW_RESET = "ORDER_NEW_RESET";

export const ORDER_CHANGE_REQUEST = "ORDER_CHANGE_REQUEST";
export const ORDER_CHANGE_SUCCESS = "ORDER_CHANGE_SUCCESS";
export const ORDER_CHANGE_FAILURE = "ORDER_CHANGE_FAILURE";
export const CLEAR_CHANGE_ERROR = "CLEAR_CHANGE_ERROR";

export const ORDER_REMOVE_MESSAGE = "ORDER_REMOVE_MESSAGE";
