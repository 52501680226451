import {
  OUTLET_DETAILS_FAILURE,
  OUTLET_DETAILS_REQUEST,
  OUTLET_DETAILS_SUCCESS,
  STORE_ANALYTICS_FAILURE,
  STORE_ANALYTICS_REQUEST,
  STORE_ANALYTICS_SUCCESS,
} from "../constants/dashboardConstants";

export const outletDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case OUTLET_DETAILS_REQUEST:
      return { loading: true };

    case OUTLET_DETAILS_SUCCESS:
      return {
        loading: false,
        outlet: action.payload,
      };

    case OUTLET_DETAILS_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const storeAnalyticsReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_ANALYTICS_REQUEST:
      return { loading: true, error: null };

    case STORE_ANALYTICS_SUCCESS:
      return {
        loading: false,
        storeAnalytics: action.payload,
      };

    case STORE_ANALYTICS_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
