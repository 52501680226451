import React from 'react'
import { Alert } from 'reactstrap'

function Message({ variant, children }) {
    return (
        <Alert color={variant}>
            {children}
        </Alert>
    )
}

export default Message
