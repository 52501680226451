import React from "react";

import { Button, Row, ButtonGroup } from "reactstrap";
import classNames from "classnames";
function Tabs({ tabs, activeTab, setActiveTab, onTabClick }) {
  return (
    <Row className="px-4 mb-4 mt-2">
      <ButtonGroup
        className="btn-group-toggle w-100 px-2"
        data-toggle="buttons"
      >
        {tabs.map((tab, i) => {
          return (
            <Button
              key={i}
              tag="label"
              color="info"
              id="0"
              size="md"
              className={classNames("btn-simple button-light-gray", {
                active: activeTab.data === tab.data,
              })}
              onClick={() => {
                setActiveTab(tab);
                onTabClick(tab);
              }}
            >
              <span className="d-none d-md-none d-lg-none d-xl-block">
                {tab.data}
              </span>
              <span className="d-block d-md-block d-lg-block d-xl-none">
                {tab.icon}
              </span>
            </Button>
          );
        })}
      </ButtonGroup>
    </Row>
  );
}

export default Tabs;

// <Row className="px-4 mb-4 mt-2">
//   <ButtonGroup
//     className="btn-group-toggle w-100 px-2"
//     data-toggle="buttons"
//   >
//     <Button
//       tag="label"
//       color="info"
//       id="0"
//       size="md"
//       className={classNames("btn-simple button-light-gray", {
//         active: activeTab.data === "All Active Orders",
//       })}
//       onClick={() => {
//         dispatch(listOrders());
//         setActiveTab({
//           data: "All Active Orders",
//           value: null,
//         });
//       }}
//     >
//       <span className="d-none d-md-block d-lg-block d-xl-block">
//         All Active
//       </span>
//       <span className="d-block d-md-none d-lg-none d-xl-none">
//         <HiOutlineClipboardList size={20} />
//       </span>
//     </Button>

//     <Button
//       tag="label"
//       color="info"
//       id="1"
//       size="md"
//       className={classNames("btn-simple button-light-gray", {
//         active: activeTab.data === "New Orders",
//       })}
//       onClick={() => {
//         dispatch(listOrders("NEW"));
//         setActiveTab({
//           data: "New Orders",
//           value: "NEW",
//         });
//       }}
//     >
//       <span className="d-none d-md-block d-lg-block d-xl-block">
//         New
//       </span>
//       <span className="d-block d-md-none d-lg-none d-xl-none">
//         <MdOutlineNotificationsActive size={20} />
//       </span>
//     </Button>

//     <Button
//       tag="label"
//       color="info"
//       id="1"
//       size="md"
//       className={classNames("btn-simple button-light-gray", {
//         active: activeTab.data === "Preparing Orders",
//       })}
//       onClick={() => {
//         dispatch(listOrders("PREPARING"));
//         setActiveTab({
//           data: "Preparing Orders",
//           value: "PREPARING",
//         });
//       }}
//     >
//       <span className="d-none d-md-block d-lg-block d-xl-block">
//         Preparing
//       </span>
//       <span className="d-block d-md-none d-lg-none d-xl-none">
//         <MdOutlineCoffeeMaker size={20} />
//       </span>
//     </Button>

//     <Button
//       tag="label"
//       color="info"
//       id="1"
//       size="md"
//       className={classNames("btn-simple button-light-gray", {
//         active: activeTab.data === "Ready Orders",
//       })}
//       onClick={() => {
//         dispatch(listOrders("READY"));
//         setActiveTab({ data: "Ready Orders", value: "READY" });
//       }}
//     >
//       <span className="d-none d-md-block d-lg-block d-xl-block">
//         Ready
//       </span>
//       <span className="d-block d-md-none d-lg-none d-xl-none">
//         <FaRegCheckCircle size={20} />
//       </span>
//     </Button>

//     <Button
//       tag="label"
//       color="info"
//       id="2"
//       size="md sm"
//       className={classNames("btn-simple button-light-gray", {
//         active: activeTab.data === "Picked Up Orders",
//       })}
//       onClick={() => {
//         dispatch(listOrders("PICKED UP"));
//         setActiveTab({
//           data: "Picked Up Orders",
//           value: "PICKED UP",
//         });
//       }}
//     >
//       <span className="d-none d-md-block d-lg-block d-xl-block">
//         Picked Up
//       </span>
//       <span className="d-block d-md-none d-lg-none d-xl-none">
//         <MdOutlineDeliveryDining size={20} />
//       </span>
//     </Button>

//     <Button
//       tag="label"
//       color="info"
//       id="1"
//       size="md"
//       className={classNames("btn-simple button-light-gray", {
//         active: activeTab.data === "Cancelled Orders",
//       })}
//       onClick={() => {
//         dispatch(listOrders("CANCELLED"));
//         setActiveTab("Cancelled Orders");
//         setActiveTab({
//           data: "Cancelled Orders",
//           value: "CANCELLED",
//         });
//       }}
//     >
//       <span className="d-none d-md-block d-lg-block d-xl-block">
//         Cancelled
//       </span>
//       <span className="d-block d-md-none d-lg-none d-xl-none">
//         <MdOutlineCancel size={20} />
//       </span>
//     </Button>
//   </ButtonGroup>
// </Row>;
