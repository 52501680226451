import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_CHANGE_FAILURE,
  ORDER_CHANGE_REQUEST,
  ORDER_CHANGE_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_REMOVE_MESSAGE,
  ORDER_NEW_RESET,
} from "../constants/orderConstants";

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
        playBell: action.playBell,
      };

    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_NEW_RESET:
      return {
        ...state,
        hasNew: false,
      };
    default:
      return state;
  }
};

export const orderChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CHANGE_REQUEST:
      return {
        loading: true,
      };

    case ORDER_CHANGE_SUCCESS:
      return {
        loading: false,
        success: action.payload,
        isPaid: action.isPaid,
      };

    case ORDER_CHANGE_FAILURE:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_REMOVE_MESSAGE:
      return {
        loading: false,
      };
    default:
      return state;
  }
};
