export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_SUCCESS = "CATEGORIES_LIST_SUCCESS";
export const CATEGORIES_LIST_FAILURE = "CATEGORIES_LIST_FAILURE";

export const SUB_CATEGORIES_LIST_REQUEST = "SUB_CATEGORIES_LIST_REQUEST";
export const SUB_CATEGORIES_LIST_SUCCESS = "SUB_CATEGORIES_LIST_SUCCESS";
export const SUB_CATEGORIES_LIST_FAILURE = "SUB_CATEGORIES_LIST_FAILURE";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_FAILURE = "PRODUCT_SEARCH_FAILURE";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_FAILURE = "PRODUCT_DELETE_FAILURE";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";

export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAILURE = "PRODUCT_DETAIL_FAILURE";

export const PRODUCT_CREATE_FAILURE = "PRODUCT_CREATE_FAILURE";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_REQUEST = "PRODUCT_DETAIL_FAILURE";
export const PRODUCT_CREATE_REMOVE_MESSAGE = "PRODUCT_CREATE_REMOVE_MESSAGE";
export const SPP_CREATE_REMOVE_MESSAGE = "SPP_CREATE_REMOVE_MESSAGE";
export const SPP_CREATE_FAILURE = "SPP_CREATE_FAILURE";
export const SPP_CREATE_REQUEST = "SPP_CREATE_REQUEST";
export const SPP_CREATE_SUCCESS = "SPP_CREATE_SUCCESS";
