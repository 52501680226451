import React from "react";
import NotificationAlert from "react-notification-alert";
function Alert({ place, color, msg, removeNotification }) {
  const notificationAlertRef = React.useRef(null);
  const notify = (place, color, msg) => {
    var type;
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: color,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  React.useEffect(() => {
    if (msg) {
      notify(place, color, msg);
    }
    // removeNotification();
  }, [place, color, msg]);
  return (
    <div className="react-notification-alert-container">
      <NotificationAlert ref={notificationAlertRef} />
    </div>
  );
}

export default Alert;
