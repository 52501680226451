import {
  OUTLETS_FETCH_FAILURE,
  OUTLETS_FETCH_REQUEST,
  OUTLETS_FETCH_SUCCESS,
} from "../constants/outletConstants";
export const outletListReducer = (state = { outlets: [] }, action) => {
  switch (action.type) {
    case OUTLETS_FETCH_REQUEST:
      return { loading: true, outlets: [] };

    case OUTLETS_FETCH_SUCCESS:
      return {
        loading: false,
        outlets: action.payload,
      };

    case OUTLETS_FETCH_FAILURE:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
