import "bootstrap/dist/css/bootstrap.min.css";
import Message from "../components/Message/Message";

import moment from "moment";
import NotificationAlert from "react-notification-alert";

import React, { useState, useEffect } from "react";
import { IoSaveSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";

import {
  listCustomers,
  updateCustomer,
  deleteCustomer,
  listDiscountTier,
  createDiscountTier,
} from "../actions/customerActions";
import { listGender } from "../actions/commonActions";
import { phone } from "./utils/conversion";
import { CUSTOMER_UPDATE_RESET } from "../constants/customerConstants";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// var DatePicker = require("reactstrap-date-picker");

function Customer({ history }) {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteCustomerModal, setDeleteCustomerModal] = React.useState(false);
  const deleteCustomertoggle = () =>
    setDeleteCustomerModal(!deleteCustomerModal);

  const [addTierModal, setAddTierModal] = React.useState(false);
  const addtiertoggle = () => setAddTierModal(!addTierModal);
  const [discountTierName, setDiscountTierName] = useState("");
  const [discountTierPercent, setDiscountTierPercent] = useState(0);
  const [discountTierCap, setDiscountTierCap] = useState(0);
  const [error, setError] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);

  const customer = useSelector((state) => state.customerList);
  const customerUpdate = useSelector((state) => state.customerUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = customerUpdate;

  const discountTierList = useSelector((state) => state.discountTierList);
  const {
    error: errorTierList,
    success: tierSuccessList,
    loading: loadingTierList,
    tier: discountTier,
  } = discountTierList;

  const discountTierCreate = useSelector((state) => state.discountTierCreate);
  const { success: tierSuccessCreate, tier: tierCreate } = discountTierCreate;

  const customerGenderList = useSelector((state) => state.customerGenderList);
  const { gender: customerGender } = customerGenderList;

  const customerDelete = useSelector((state) => state.customerDelete);
  const { success: customerSuccessDelete } = customerDelete;

  const [customerData, setCustomerData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    gender: null,
    email: null,
    birth_date: null,
    discount_tier: "",
  });

  useEffect(() => {
    if (successUpdate || customerSuccessDelete || tierSuccessList) {
      history.push("/admin/customer-view");
      // fetchFreshData();
    }
  }, [
    dispatch,
    history,
    successUpdate,
    customerSuccessDelete,
    tierSuccessList,
  ]);

  useEffect(() => {
    dispatch(listGender());
    dispatch(listDiscountTier());
    if (tierSuccessCreate) {
      setCustomerData({
        ...customerData,
        discount_tier: tierCreate["data"]["id"],
      });
    }
  }, [dispatch, history, tierSuccessCreate]);

  const handleChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };
  console.log(customerData);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateCustomer({
        username: customerData.username,
        first_name: customerData.first_name,
        last_name: customerData.last_name,
        phone_number: customerData.phone_number,
        email: customerData.email,
        customer: {
          gender: customerData.gender,
          birth_date:
            customerData.birth_date === undefined ||
            customerData.birth_date == null ||
            Object.keys(customerData.birth_date).length <= 0
              ? null
              : moment(customerData.birth_date).format("DD-MM-YYYY"),
          discount_tier: customerData.discount_tier,
        },
      })
    );
    notify("tr", "dark", "Customer discount tier updated successfully");
  };

  const individualCustomerData = (activeCustomer) => {
    setCustomerData({
      ...customerData,
      username: activeCustomer.username,
      first_name: activeCustomer.first_name,
      last_name: activeCustomer.last_name,
      phone_number: activeCustomer.phone_number,
      gender: activeCustomer.customer["gender"],
      email: activeCustomer.email,
      birth_date: activeCustomer.customer["birth_date"],
      discount_tier: activeCustomer.customer["discount_tier"],
    });
    if (successUpdate) {
      dispatch(listGender());
      dispatch(listDiscountTier());
      dispatch({ type: CUSTOMER_UPDATE_RESET });
    }
  };
  const searchHandler = () => {
    if (searchQuery !== "") {
      dispatch(listCustomers(searchQuery));
    }
  };

  const notificationAlertRef = React.useRef(null);
  const notify = (place, color, err) => {
    var type;
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{err}</b>
          </div>
        </div>
      ),
      type: color,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="6">
            <Card className="px-4 py-2">
              <Row className="w-100 d-flex justify-content-between align-items-center px-4px">
                <Row className="w-100 d-flex justify-content-between align-items-center mx-4 mt-4">
                  <Col className="p-0 m-0 ml-3">
                    <Input
                      placeholder="Phone Number"
                      type="text"
                      className="form_input"
                      value={searchQuery}
                      onChange={(e) => handleChange(e)}
                    />
                  </Col>

                  <Col className="p-0 ml-3">
                    <Button
                      className="button-light-gray"
                      onClick={searchHandler}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Row>

              <CardBody className="mt-3">
                <Table className="tablesorter mx-2">
                  <thead>
                    <tr className="w-100 d-flex">
                      <th className="col">Phone Number</th>
                      <th className="col">Customer Name</th>
                    </tr>
                  </thead>
                </Table>

                <hr className="bg-dark" style={{ opacity: "0.4" }} />

                {customer.customers.length !== 0 ? (
                  customer.customers.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          individualCustomerData(item);
                        }}
                      >
                        <Card
                          className="px-0 py-3 m-0"
                          color={
                            customerData.username === item.username
                              ? "default"
                              : ""
                          }
                        >
                          <tr className="w-100 d-flex">
                            <td className="col">{phone(item.phone_number)}</td>
                            <td className="col">
                              {item.first_name + " " + item.last_name}
                            </td>
                          </tr>
                        </Card>

                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <Card className="p-1 mt-3 text-center">
                    <div
                      className="font-weight-bold mt-4"
                      style={{ opacity: 0.3 }}
                    >
                      No Customers Available
                    </div>
                  </Card>
                )}
              </CardBody>
            </Card>
          </Col>

          {/* --------------------- Right Pannel --------------------- */}
          <Col md="6">
            <Card className="px-4 py-3">
              <CardHeader>
                <Row className="w-100 d-flex justify-content-between align-items-center">
                  <div className="col">
                    <h4 className=" font-weight-bold">Profile Information</h4>
                  </div>

                  <div>
                    <Button className="button-orange">Analytics</Button>
                  </div>
                </Row>

                <hr className="m-0 mt-2"></hr>
              </CardHeader>

              <CardBody>
                <Row className="w-100 d-flex justify-content-between align-items-center px-4">
                  <Col lg="6">
                    {errorUpdate && (
                      <Message variant="danger">{errorUpdate}</Message>
                    )}
                  </Col>
                </Row>

                <div className="row">
                  <Form onSubmit={submitHandler} id="customerProfileForm">
                    <div className="row mx-1">
                      <FormGroup className="col-6">
                        <Label
                          for="exampleName"
                          className="text-dark font-weight-bold "
                          style={{ opacity: "0.6" }}
                        >
                          First Name
                        </Label>
                        <Input
                          type="name"
                          className="form_input"
                          name="first_name"
                          required
                          aria-required="true"
                          placeholder="Enter First Name"
                          value={customerData.first_name}
                          disabled={true}
                          onChange={(e) =>
                            setCustomerData({
                              ...customerData,
                              first_name: e.target.value,
                            })
                          }
                        />
                      </FormGroup>

                      <FormGroup className="col-6">
                        <Label
                          for="exampleName"
                          className="text-dark font-weight-bold "
                          style={{ opacity: "0.6" }}
                        >
                          Last Name
                        </Label>
                        <Input
                          type="name"
                          className="form_input"
                          name="last_name"
                          required
                          aria-required="true"
                          placeholder="Enter Last Name"
                          value={customerData.last_name}
                          disabled={true}
                          onChange={(e) =>
                            setCustomerData({
                              ...customerData,
                              last_name: e.target.value,
                            })
                          }
                        />
                      </FormGroup>

                      <FormGroup className="col-6">
                        <Label
                          for="exampleGender"
                          className="text-dark font-weight-bold "
                          style={{ opacity: "0.6" }}
                        >
                          Gender
                        </Label>
                        <Input
                          id="exampleSelect"
                          placeholder="Gender"
                          type="select"
                          disabled={true}
                          className="form_input"
                          onChange={(e) =>
                            setCustomerData({
                              ...customerData,
                              gender: e.target.value,
                            })
                          }
                        >
                          <option value={customerData.gender} selected>
                            {customerGender?.filter(
                              (i) => i.id === customerData.gender
                            )[0] === undefined
                              ? ""
                              : customerGender?.filter(
                                  (i) => i.id === customerData.gender
                                )[0].name}
                          </option>
                          {customerGender !== undefined &&
                            customerGender.length > 0 &&
                            customerGender.map((i) =>
                              i.id === customerData.gender ? (
                                ""
                              ) : (
                                <option value={i.id} key={i.id}>
                                  {i.name}
                                </option>
                              )
                            )}
                        </Input>
                      </FormGroup>

                      <FormGroup className="col-6">
                        <Label
                          for="exampleEmail"
                          className="text-dark font-weight-bold "
                          style={{ opacity: "0.6" }}
                        >
                          Email
                        </Label>
                        <Input
                          type="email"
                          className="form_input"
                          placeholder="Enter Email"
                          disabled={true}
                          value={
                            customerData.email === null
                              ? ""
                              : customerData.email
                          }
                          onChange={(e) =>
                            setCustomerData({
                              ...customerData,
                              email: e.target.value,
                            })
                          }
                        />
                      </FormGroup>

                      <FormGroup className="col-6">
                        <Label
                          className="text-dark font-weight-bold "
                          style={{ opacity: "0.6" }}
                        >
                          Date Of Birth
                        </Label>
                      </FormGroup>

                      <FormGroup className="col-6">
                        <Label
                          for="exampleBirthDate"
                          className="text-dark font-weight-bold "
                          style={{ opacity: "0.6" }}
                        >
                          Discount Tier
                        </Label>
                        <Input
                          id="exampleSelect"
                          className="form_input"
                          placeholder="Enter Discount Tier"
                          type="select"
                          onChange={(e) =>
                            setCustomerData({
                              ...customerData,
                              discount_tier: e.target.value,
                            })
                          }
                        >
                          <option value={customerData.discount_tier} selected>
                            {discountTier?.filter(
                              (i) => i.id === customerData.discount_tier
                            )[0] === undefined
                              ? ""
                              : discountTier?.filter(
                                  (i) => i.id === customerData.discount_tier
                                )[0].name}
                          </option>
                          {discountTier !== undefined &&
                            discountTier.length > 0 &&
                            discountTier.map((tier) =>
                              tier.id === customerData.discount_tier ? (
                                ""
                              ) : (
                                <option value={tier.id} key={tier.id}>
                                  {tier.name}
                                </option>
                              )
                            )}
                        </Input>
                      </FormGroup>
                    </div>
                  </Form>

                  <div className="col-12 col-lg-6">
                    <div className="row">
                      <div className="col-6">
                        {customerData.username === undefined ||
                        customerData.username === null ||
                        Object.keys(customerData.username).length <= 0 ? (
                          <Button
                            type="submit"
                            className="button-light-gray text-dark w-90"
                            disabled
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            form="customerProfileForm"
                            className="button-green w-90"
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Customer;
