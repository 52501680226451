/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import BaristaView from "views/BaristaView";
import LoginView from "views/Login";
import { FaCreditCard } from "react-icons/fa";
import Customer from "views/Customer";
import Product from "views/ProductView";
import AddProduct from "views/AddProduct";

import { IoStorefront } from "react-icons/io5";
import { RiCupFill, RiLoginBoxFill, RiUserFill } from "react-icons/ri";
import { MdFastfood } from "react-icons/md";

var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: <IoStorefront size={20} />,
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/barista-view",
    name: "Barista View",
    icon: <RiCupFill size={20} />,
    component: BaristaView,
    layout: "/admin",
  },
  {
    path: "/login-view",
    name: "Login View",
    icon: <RiLoginBoxFill size={20} />,
    component: LoginView,
    layout: "/admin",
  },

  {
    path: "/customer-view",
    name: "Customer View",
    icon: <RiUserFill size={20} />,
    component: Customer,
    layout: "/admin",
  },
  // {
  //   path: "/product-view",
  //   name: "Product View",
  //   icon: <MdFastfood size={20} />,
  //   component: Product,
  //   layout: "/admin",
  // },
  // {
  //   path: "/add-product",
  //   name: "Add Product",
  //   icon: <MdFastfood size={20} />,
  //   component: AddProduct,
  //   layout: "/admin",
  // },

  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/map",
  //   name: "Map",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-pin",
  //   component: Map,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin",
  // },

  // {
  //   path: "/tables",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin",
  // },
];
export default routes;
